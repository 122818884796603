<template>

  <div class="row info-tiles">
    <div
      v-for="(info, idx) in data"
      :key="idx"
      :class='["flex xs12 info-tiles__wrap", info.rowClass || "sm4"]'>
      <vac-card :class='["mb-4", info.className || ""]' :color="info.color" style="display: flex;">
        <div style="width: fit-content;">
          <p :style="{'color': info.textColor || 'white'}" class="display-2 mb-1">{{ info.value }}</p>
          <p :style="{'color': info.textColor || 'white'}" class="subtext">{{ $t(info.text) }}</p>
        </div>
        <div v-if="showCircle" class="cards__percentage">
<!--          <VacChart :data="calcChartData(info)" :options="chartOptions" type="donut"/>-->
          <Doughnut
            :dataset-id-key="'label'"
            :chart-options="chartOptions"
            :chart-data="calcChartData(info)"
            :height="70"
            :style="{height: '70px'}"
          />
          <span class="text">{{ calcChartData(info).percentage }}</span>
        </div>
      </vac-card>
    </div>
  </div>

</template>

<script>
import VacChart from "../../components/VacChart";
import { Doughnut } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement)

export default {
  name: 'InfoTiles',

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    showCircle: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    // VacChart
    Doughnut
  },

  data() {
    return {
      // chartOptions: {
      //   cutoutPercentage: 80,
      //   legend: {
      //     display: false,
      //   },
      //   tooltips: {
      //     enabled: false,
      //   },
      //   scales: {
      //     xAxes: [{display: false}],
      //   },
      // },
    };
  },

  computed: {
    chartOptions() {
      return {
        cutout: '80%',
        scales: {
          x: {display: false},
        },
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
          },
        }
      }
    },
  },

  methods: {
    calcChartData(item) {
      if (item) {
        const percentage = ((item.checked / item.total || 0) * 100).toFixed(0);
        let color1, color2;
        if (percentage < 50) {
          color1 = '#a50034';
        } else if (percentage >= 50 && percentage < 90) {
          color1 = '#f3972b';
        } else {
          color1 = '#45db54';
        }
        color2 = '#E5E5E5'

        return {
          percentage: percentage + '%',
          datasets: [
            {
              borderColor: "transparent",
              backgroundColor: [color1, color2],
              label: '',
              data: [percentage >= 50 ? percentage : 100 - percentage, percentage >= 50 ? 100 - percentage : percentage],
            },
          ],
          labels: ['', ''],
        };
      }
    },
  },
};
</script>

<style lang="scss">
.doughnut {
  height: 70px;
}

.info-tiles {
  .stream-color {
    background: linear-gradient(35.34deg, rgb(76, 150, 197) 0%, rgb(154, 106, 162) 50.52%, rgb(216, 72, 133) 100%);
    text-align: center;
  }

  .info-color {
    color: rgb(255, 255, 255);
    box-shadow: rgba(52, 56, 85, 0.25) 0 2px 3px 0;
    background: linear-gradient(to right, rgb(52, 181, 229), rgb(46, 132, 224));
  }

  .center {
    text-align: center;
  }

  &__wrap {
    .vac-card {
      &__body {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.cards {
  &__percentage {
    width: 70px;
    height: 70px;
    z-index: 1;
    position: relative;
    margin-left: auto;

    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
    }
  }
}

@media print {
  .info-tiles {
    margin: 10px 0;

    &__wrap {
      .vac-card {
        div {
          text-align: center;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
